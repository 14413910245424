@charset "utf-8";

.clear10 {
  clear: both;
  height: 10px;
}

.clear20 {
  clear: both;
  height: 20px;
}

/* 메인 배너 */

.offline_main_banner {
  width: 500px;
  height: 180px;
  float: left;
  background-color: #e5e5e5;
  .banner_container {
    position: relative;
    width: 500px;
    height: 180px;
    margin: 0 auto;
    cursor: pointer;
    overflow: hidden;
    > {
      #larr {
        position: absolute;
        left: 20px;
        top: 80px;
        z-index: 3;
      }
      #rarr {
        position: absolute;
        right: 20px;
        top: 80px;
        z-index: 3;
      }
      .imgs_container {
        position: absolute;
        left: 0px;
        top: 0px;
        img {
          float: left;
          width: 500px;
          height: 180px;
        }
      }
    }
  }
  .roll_navi {
    position: absolute;
    right: 26px;
    bottom: 10px;
    z-index: 3;
    .roll_point {
      float: left;
      width: 12px;
      height: 12px;
      background-color: #999;
      margin-left: 5px;
      overflow: hidden;
      div {
        width: 12px;
        height: 12px;
        background-color: #FFF;
        display: none;
      }
    }
  }
}

/* 마감임박 학원강의 */

#offline_lecture_postscript {
  .title_table {
    width: 810px;
    border: 0px;
    > img {
      vertical-align: middle;
    }
  }
  .lecture_container {
    /* width: 788px; */
    height: 258px;
    border: 1px solid #d5daed;
    background-color: #f5f6fa;
    position: relative;
    overflow: hidden;
    padding: 10px;
    .slide_container {
      position: absolute;
      overflow: hidden;
      top: 0px;
      margin-top: 10px;
    }
    .lecture_list_item {
      width: 785px;
      height: 70px;
      border: 1px solid #bec6cf;
      background-color: #fff;
      margin-bottom: 10px;
      clear: both;
      &:after {
        clear: both;
      }
    }
    .no_data {
      width: 808px;
      height: 260px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      line-height: 250px;
    }
    .lecture_list_item {
      .divider {
        float: left;
        margin-left: 1px;
        margin-right: 1px;
        width: 1px;
        height: 70px;
        background: url('/static/images/offline/divider.png') no-repeat;
      }
      .lecture_thumb {
        float: left;
        width: 55px;
        padding: 1px;
        height: 36px;
        margin: 9px;
        vertical-align: middle;
        text-align: center;
        line-height: 50px;
        position: relative;
        img {
          vertical-align: middle;
        }
      }
      .lecture_desc {
        float: left;
        width: 220px;
        height: 50px;
        margin: 9px 1px;
        font-weight: bold;
        color: #505050;
        td {
          width: 246px !important;
          height: 50px;
          vertical-align: middle;
          font-weight: bold;
        }
        .subject {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 5px 0 0;
        }
      }
      .teacher_name {
        margin-top: 20px;
        float: left;
        width: 70px;
        height: 50px;
        text-align: center;
        color: #858484;
        display: inline;
        vertical-align: middle;
        line-height: 15px;
      }
      .week {
        margin-top: 20px;
        float: left;
        width: 87px;
        height: 50px;
        text-align: center;
        color: #858484;
        line-height: 15px;
      }
      .time {
        margin-top: 20px;
        float: left;
        width: 102px;
        height: 50px;
        text-align: center;
        color: #858484;
        line-height: 15px;
      }
      .btn_row {
        margin-top: 20px;
        float: left;
        height: 50px;
        width: 80px;
        text-align: center;
        color: #858484;
        line-height: 15px;
      }
      .crumbs {
        overflow: hidden;
        margin: 0 0 3px;
        .new {
          padding: 2px;
          margin: 1px 3px;
          height: 14px;
          line-height: 11px;
          background-color: #447fd7;
          font-size: 11px;
          color: #fff;
          float: left;
        }
        .end {
          padding: 2px;
          margin: 1px 3px;
          height: 14px;
          line-height: 11px;
          background-color: #888;
          font-size: 11px;
          color: #fff;
          float: left;
        }
        .sale {
          padding: 2px;
          margin: 1px 3px;
          height: 14px;
          line-height: 11px;
          background-color: #36b53f;
          font-size: 11px;
          color: #fff;
          float: left;
        }
        .finish {
          margin: 1px 3px;
          height: 14px;
          float: left;
        }
      }
    }
  }
}

/* 학원수강 후기 */

.offline_main_ep {
  width: 400px;
  height: 220px;
  clear: both;
  float: left;
  margin-top: 10px;
  > {
    .ep_header {
      height: 30px;
      > {
        .header_title {
          float: left;
        }
        .header_more {
          float: right;
          padding: 5px 7px;
          padding-right: 0px;
        }
      }
    }
    .ep_body {
      &:before {
        clear: both;
      }
      border: 1px solid #b3c9e4;
      height: 170px;
    }
  }
}



/* pagination */

#pagination_wrapper {
  width: 810px;
  background-color: #f6f6f6;
  .pagination_conatiner {
    background-color: #f6f6f6;
    border-color: #f6f6f6;
    width: 810px;
    margin: 0 auto;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

#offline_pagination {
  margin: 0 auto;
  .pages {
    display: inline-block;
    height: 26px;
    background: url('/static/images/offline/bg_pagination.gif') repeat-x;
    border: 1px solid #dcdddc;
    padding-top: 5px;
    margin-left: 3px;
    margin-right: 3px;
    a {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
      font-weight: bold;
      &:hover {
        text-decoration: none;
        color: #0e79c9;
      }
      &.current {
        color: #0e79c9;
      }
    }
  }
}

/* 합격자 현황 */

.selarea {
  margin: 20px 0 10px 0;
  width: 810px;
}

.selinput {
  padding: 3px 5px;
  height: 28px;
  margin-right: 5px;
  border-left: 3px solid #016ab9;
}

.thead tr th {
  height: 35px;
  border-top: 2px solid #000;
  background-image: url('/_skin/images/postscript_bg.jpg');
}

.tbody tr {
  td {
    padding: 10px 5px;
  }
  &:nth-child(2n) td {
    background-color: #f1f7fa;
  }
  &:nth-child(2n+1) td {
    background-color: #ffffff;
  }
}

/* 무료 특강 */

#special_status {
  list-style: none;
  li {
    float: left;
    > a {
      font-size: 20px;
      color: #969ca0;
      font-weight: bold;
      padding: 8px;
      &:hover {
        text-decoration: none;
      }
    }
    &.selected > a {
      color: #3b3b3b;
    }
    span.count {
      color: #016ab9;
      font-weight: bold;
    }
    &.selected > a:hover {
      color: #3b3b3b;
    }
    &:hover a, a:hover {
      text-decoration: none !important;
    }
  }
  .count {
    font-family: helvetica, sans-serif;
    font-size: 24px;
    vertical-align: middle;
  }
  li img {
    vertical-align: middle;
  }
}

.list_item {
  width: 386px;
  height: 350px;
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #bec6cf;
  float: left;
  margin-bottom: 10px;
  &:hover {
    border: 1px solid #016ab9;
  }
  > .status {
    position: absolute;
    left: 5px;
    top: 170px;
  }
  .thumbs {
    width: 386px;
    height: 200px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    cursor: pointer;
  }
  > {
    .title_row {
      width: 368px;
      height: 32px;
      padding: 8px;
      border-top: 1px solid #bec6cf;
      border-bottom: 1px solid #bec6cf;
      cursor: pointer;
      > .title {
        display: block;
        font-size: 15px;
        font-weight: bold;
      }
      &:hover {
        > .title, > .sub_title {
          color: #016ab9;
        }
      }
      > .sub_title {
        display: block;
        font-size: 15px;
        font-weight: bold;
      }
    }
    .desc_row {
      padding: 10px;
      color: #9d9c9c;
    }
    .btn_row {
      padding: 5px 10px;
      list-style: none;
    }
    .mask {
      background-color: #4a4a4a;
      opacity: 0.6;
      filter: alpha(opacity = 60);
      position: absolute;
      z-index: 99;
      width: 386px;
      height: 200px;
      top: 0px;
      left: 0px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      > .mask_caption {
        display: table-cell;
        height: 200px;
        vertical-align: middle;
        width: 386px;
        text-align: center;
        font-size: 60px;
        color: #FFF;
        font-weight: bold;
      }
    }
  }
}

.column2 {
  margin-left: 10px;
}

.btn_row li {
  float: left;
  margin-right: 10px;
  &:hover img {
    opacity: 0.4;
    filter: alpha(opacity = 40);
  }
}

/* 무료특강 수강신청 */

#request_table {
  width: 100%;
  border-top: 2px solid #454545;
  border-bottom: 2px solid #454545;
  background-color: #f8f8f8;
  th {
    width: 100px;
    padding: 10px;
    text-align: left;
    height: 50px;
    border-bottom: 1px solid #d0d0d0;
    font-size: 15px;
  }
  td {
    padding: 10px;
    text-align: left;
    height: 50px;
    border-bottom: 1px solid #d0d0d0;
    font-size: 15px;
    input {
      height: 28px;
      font-size: 15px;
      padding-left: 10px;
      &[readonly] {
        background-color: #d9d9d9;
      }
    }
    select {
      height: 30px;
      font-size: 15px;
      padding-left: 10px;
    }
  }
}

#btn_request {
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
    filter: alpha(opacity = 40);
  }
}

.img_button:hover {
  opacity: 0.4;
  filter: alpha(opacity = 40);
  cursor: pointer;
}